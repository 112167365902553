import { graphql } from "gatsby"
import "../stylesheets/general.css"
import React from "react"
import {
  Button,
  Card,
  Content,
  Hero,
  Layout,
  Subscribe,
  TopTacticsCard,
} from "../components"
import { TopTacticsList } from "../elements"

const TacticsPage = ({ data }) => {
  const tactics = data.allWordpressPost.nodes
  return (
    <Layout>
      <Hero
        title="The Future Is Here."
        videoUrl="https://itspixelperfect.s3.amazonaws.com/videos/About+IPP+-+Strategy+Loop.mp4"
        buttonTitle="Watch the Video"
        image={data.heroImage.fluid}
        hasBgVideo={true}
        description="We have implemented transformational digital strategies for leading brands."
      />
      <Content>
        <Card
          name="Ideas Matter."
          title="Digital transformation is not a final destination. It is a process of change requiring a visionary mindset aligned with sound strategy and constant innovation."
          description="Here is where you’ll find the latest industry insights, news on innovation and exciting announcements.  From culturally relevant digital marketing tips to how we maximize clients’ return on investment. Your exclusive pass to the hub of ideas that matter."
        />
      </Content>
      <TopTacticsList>
        {tactics.map((tactic, index) => (
          <TopTacticsCard key={index} tactic={tactic} />
        ))}

        <Button width="medium" margin="auto" to="/archive" variant="solid">
          View All Tactics
        </Button>
      </TopTacticsList>
      <Subscribe />
    </Layout>
  )
}
export const query = graphql`
  query {
    heroImage: imageSharp(fluid: { originalName: { eq: "tactics-hero.jpg" } }) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
    allWordpressPost(limit: 5) {
      nodes {
        id
        author {
          name
        }
        title
        slug
        date(formatString: "MMMM DD, YYYY")
        featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 430, maxHeight: 215, quality: 100) {
                srcSet
              }
            }
          }
        }
        categories {
          name
        }
      }
    }
  }
`
export default TacticsPage
